<template>
  <tr class="add-row">
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('email'))">
        <input-text v-model="email" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('first_name'))">
        <input-text v-model="first_name" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('last_name'))">
        <input-text v-model="last_name" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('is_primary'))">
        <input-select v-model="is_primary" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('parent_type'))">
        {{ rowData.parent_type }}
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('parent_name'))">
        {{ rowData.parent_name }}
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('invite_status'))"
      >
        {{ rowData.invite_status }}
      </div>
    </td>
  </tr>
</template>

<script>
import InputSelect from '@/components/inputs/InputSelect'
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
  name: 'NewRowContact',
  mixins: [newRowMixin],
  components: { InputSelect },

  data() {
    return {
      email: {
        value: '',
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        regexp:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorKey: '',
        class: 'sm',
      },
      first_name: {
        value: '',
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },
      last_name: {
        value: '',
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      is_primary: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
          {
            id: true,
            name: 'Yes',
          },
          {
            id: false,
            name: 'No',
          },
        ],
        class: 'sm',
      },
    }
  },

  created() {
    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        if (element && typeof element === 'object' && element.length)
          this.$data[key].value = element
        else if (key === 'is_primary') this.$data[key].value = [element]
        else this.$data[key].value = element

        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }
  },

  computed: {
    isValid() {
      if (
        !this.email.isValid ||
        !this.first_name.isValid ||
        !this.last_name.isValid
      )
        return false

      return true
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel')
    },

    save() {
      if (!this.isValid) return

      this.$emit('save', this.rowForSave())
    },
  },
}
</script>
