<template>
  <div>
    <header-page :title="'Contacts'" :hasBackLink="false" />

    <div class="filters-container">
      <div class="filters-block">
        <div class="search">
          <input-text v-model="search_text" />
          <div
            v-if="isShowClear"
            class="main-button lg ml-15"
            @click="clearFilters"
          >
            <div>Clear</div>
          </div>
        </div>
        <div class="button">
          <div v-if="false" class="main-button lg orange" @click="setFilters">
            <div class="icon" v-html="Filter"></div>
            <div>Filters</div>
          </div>
        </div>
      </div>
      <div class="selected-filters" v-if="selectedFilters.length">
        <div v-for="item in selectedFilters" :key="item.text">
          <div class="icon" @click="removeFilter(item.id)" v-html="Cross"></div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      :listTabs="listTabs"
      @setTab="setTab"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntity"
      @saveRow="saveEntity"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
    />
    <set-filters v-if="isVisibleFilters" v-model="isVisibleFilters" />
    <alert-yes-no
      v-if="isVisibleConfirmSendInvite"
      title="Confirm"
      text=""
      :alertResult="alertConfirmSendInvite"
    />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import NewRowEntity from '@/components/NewRows/NewRowContact'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'Contacts',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isSelectable: false,
        isEditable: hasAccess(permissions.get_contacts),
        isAdd: false,
        isDelete: hasAccess(permissions.get_contacts),
        deleteButtonText: '',
        addButtonText: 'Add contact',
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">Are you sure you want to delete contact <b>&nbsp;${rows[0].email}</b>?</div>`
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'email',
          text: 'Email Address',
          cssClass: 'bold-underline',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'first_name',
          text: 'First name',
          width: '180px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'last_name',
          text: 'Last name',
          width: '180px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'is_primary',
          text: 'Primary',
          width: '180px',
          formatedValue: ({ row }) => {
            return row?.is_primary ? 'Yes' : 'No'
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'parent_type',
          text: 'Partner/Client',
          width: '200px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'parent_name',
          text: 'Client Name/Partner Name',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row }) => {
            const url =
              row?.parent_type === 'partner'
                ? `/link-builder-partner/${row.parent_id}`
                : `/client/${row.parent_id}`
            if (value) return `<a href="${url}" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'invite_status',
          text: 'Invite status',
          width: '200px',
        }),
      ],
      newEntity: {
        email: '',
        first_name: '',
        last_name: '',
        is_primary: false,
        parent_type: '',
        parent_id: 0,
        parent_name: '',
        invite_status: '',
      },
      newRowComponent: shallowRef(NewRowEntity),
      isVisibleConfirmSendInvite: false,
      idInviteEntity: 0,

      listTabs: [
        {
          id: 'all',
          name: 'All',
          count: 0,
          isActive: true,
          isVisible: true,
        },

        {
          id: 'partner',
          name: 'Partner contacts',
          count: 0,
          isActive: false,
          isVisible: true,
        },
        {
          id: 'client',
          name: 'Clients contacts',
          count: 0,
          isActive: false,
          isVisible: true,
        },
      ],
      activeTab: 'all',
    }
  },

  // watch: {
  //   activeTab() {
  //     this.setOptionalColumnsAndGrig()
  //   },
  // },

  mounted() {
    document.addEventListener('click', this.showAlertConfirmSendInvite)
    document.addEventListener('click', this.activateEntity)
  },

  beforeUnmount() {
    document.removeEventListener('click', this.showAlertConfirmSendInvite)
    document.removeEventListener('click', this.activateEntity)
  },

  created() {
    //this.setOptionalColumnsAndGrig()
    this.setDataFromQueryParams() // 3
    this.loadCountTabs()
    this.fetchData()
  },

  methods: {
    // setOptionalColumnsAndGrig() {
    //   let colsKeyUrlIndex = this.columns.length + 2
    //   if (this.activeTab === 'active') {
    //     this.options.isEditable = hasAccess(permissions.get_contacts)
    //     //this.options.isAdd = hasAccess(permissions.get_contacts)
    //     this.options.isDelete = false

    //     let idx = this.columns.findIndex((column) => column.key === 'activate')
    //     if (idx > -1) this.columns.splice(idx, 1)

    //     this.columns.push(
    //       new GridColumn({
    //         keyUrl: 's' + colsKeyUrlIndex++,
    //         key: 'invite_status',
    //         text: 'Invite status',
    //         positionDefault: 14,
    //         formatedValue: ({ row }) => {
    //           if (row?.is_active)
    //             return `<div class="underline hand js-send-invite" data-id="${row.id}">Send invite</div>`
    //           return ''
    //         },
    //         width: '150px',
    //       })
    //     )
    //   } else if (this.activeTab === 'not-active') {
    //     this.options.isEditable = false
    //     //this.options.isAdd = false
    //     this.options.isDelete = false

    //     let idx = this.columns.findIndex(
    //       (column) => column.key === 'invite_status'
    //     )
    //     if (idx > -1) this.columns.splice(idx, 1)

    //     this.columns.push(
    //       new GridColumn({
    //         keyUrl: 's' + colsKeyUrlIndex++,
    //         key: 'activate',
    //         text: 'Activate',
    //         positionDefault: 14,
    //         formatedValue: ({ row }) => {
    //           if (!row?.is_active)
    //             return `<div class="underline hand js-activate-entity" data-id="${row.id}">Activate</div>`
    //           return ''
    //         },
    //         width: '150px',
    //       })
    //     )
    //   }
    // },

    async loadCountTabs() {
      let query = `page=1&per_page=1`
      let all = await fetchData(
        urls.contacts,
        'GET',
        null,
        query + `&contact_parent_f=all`
      )
      let allTab = this.listTabs.find((item) => item.id === 'all')
      allTab.count = all?.count || 0

      let partner = await fetchData(
        urls.contacts,
        'GET',
        null,
        query + `&contact_parent_f=partner`
      )
      let partnerTab = this.listTabs.find((item) => item.id === 'partner')
      partnerTab.count = partner?.count || 0

      let client = await fetchData(
        urls.contacts,
        'GET',
        null,
        query + `&contact_parent_f=client`
      )
      let clientTab = this.listTabs.find((item) => item.id === 'client')
      clientTab.count = client?.count || 0
    },

    setTab(id) {
      if (!id) return

      this.activeTab = id
      this.activateTab(id)
    },

    async activateEntity(e) {
      if (!e.target.classList.contains('js-activate-entity')) return
      let id = e.target.getAttribute('data-id')

      const entity = { id: Number(id), is_active: true }
      await this.editRow(entity)
      this.fetchData()
      this.loadCountTabs()
    },

    showAlertConfirmSendInvite(e) {
      if (!e.target.classList.contains('js-send-invite')) return
      this.idInviteEntity = e.target.getAttribute('data-id')
      this.isVisibleConfirmSendInvite = true
    },

    async alertConfirmSendInvite(result) {
      if (result) await this.sendInvite()

      this.isVisibleConfirmSendInvite = false
      this.idInviteEntity = 0
    },

    // async sendInvite() {
    //   let result = await fetchData(
    //     urls.entitysSendInvite + `/${this.idInviteEntity}`,
    //     'GET'
    //   )

    //   this.$notify({
    //     text: result,
    //     type: 'success',
    //     duration: 10000,
    //   })
    // },

    saveNewEntity(entity) {
      this.setNewEntity(entity)
      this.addRow()
    },

    saveEntity(entity) {
      this.editRow(entity)
    },

    newEntityToDefault() {
      for (let key in this.newEntity) {
        this.newEntity[key] = null
      }
    },

    setNewEntity(entity) {
      for (let key in entity) {
        this.newEntity[key] = entity[key]
      }
    },

    async editRow(entity) {
      this.$store.commit('setIsLoading', true)
      const id = entity.id
      delete entity.id

      const row = this.rows.find((item) => item.id === id)
      let url = ''
      if (row.parent_type === 'partner') url = urls.partnerContact
      else if (row.parent_type === 'client') url = urls.clientContact

      entity = { ...entity, ...{ [`${row.parent_type}_id`]: row.parent_id } }

      let result = await fetchData(
        url + `/${id}`,
        'POST',
        this.bodyToSave(entity)
      )
      if (!result?.detail) {
        let entityRow = this.rows.find((item) => item.id === id)
        if (entityRow) {
          for (const key in entity) {
            if (key in entityRow) entityRow[key] = entity[key]
          }
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      // Додавання не реалізовано і просто пермішенами тут не обійтися, бо у таблици присутні різні типи об'єктів
      // Якщо буде реалізація у майбутньому, то треба додати обирання тіпу парента - це довго.
      // А потім ще тут треба зформувати правильний контракт відповідно редагуванню
      // this.$store.commit('setIsLoading', true)
      // let result = await fetchData(
      //   urls.entityCreate,
      //   'POST',
      //   this.bodyToSave(this.newEntity)
      // )
      // if (!result?.detail) {
      //   const tmpEntity = this.newEntity
      //   this.rows.unshift({ ...{ id: result.id }, ...tmpEntity })
      //   this.isHideNewRowComponent = true
      //   this.$nextTick(() => {
      //     this.isHideNewRowComponent = false
      //   })
      //   this.newEntityToDefault()
      // } else {
      //   this.isHideNewRowComponent = false
      //   console.error(result.detail)
      // }
      // this.$store.commit('setIsLoading', false)
    },

    async deleteRow(id) {
      this.$store.commit('setIsLoading', true)

      const row = this.rows.find((item) => item.id === id)
      let url = ''
      if (row.parent_type === 'partner') url = urls.partnerContact
      else if (row.parent_type === 'client') url = urls.clientContact

      let result = await fetchData(url + `/${id}`, 'DELETE')
      if (!result?.detail) {
        const idx = this.rows.findIndex((item) => item.id === id)
        this.rows.splice(idx, 1)
      } else console.error(result.detail)
      this.loadCountTabs()
      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += `&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(urls.contacts, 'GET', null, query)
      console.table(result.rows)

      this.rows = result?.rows || []

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    queryToFilters() {
      let searchParams = new URLSearchParams()
      searchParams.append('contact_parent_f', this.activeTab)
      return searchParams.toString()
    },

    setFilters() {
      this.isVisibleFilters = !this.isVisibleFilters
    },
  },
}
</script>
